import { htmlClass } from '@nosinovacao/nosid-mfe-common';
import { FC } from 'react';
import { ContainerSkinProps } from '../container.props';

export const ContainerBenfica: FC<ContainerSkinProps> = ({
  children,
  containerClassName,
  className,
  el,
  onSubmit,
}) => {
  const klass = htmlClass(
    'max-desktop-sm:overflow-x-hidden max-desktop-sm:w-full relative  pt-8  flex flex-col flex-nowrap justify-start items-start   z-10  max-desktop-sm:h-svh',
    'tablet-sm:pt-[120px] tablet-sm:mx-auto ',
    'tablet-st:px-0',
    'desktop-sm:w-svw desktop-sm:min-h-containerMinHeight desktop-sm:overflow-y-auto desktop-sm:max-h-containerMaxHeight desktop-sm:shadow-level4 desktop-sm:rounded-lg desktop-sm:items-center  desktop-sm:mx-0 desktop-sm:max-w-[636px] desktop-sm:pt-0 desktop-sm:overflow-hidden desktop-sm:bg-grey-900',
    className ?? '',
  );

  const containerClass = htmlClass(
    ' tablet-sm:flex tablet-sm:justify-center tablet-sm:items-center tablet-sm:h-[100dvh] bg-grey-900',
    containerClassName ?? '',
  );

  switch (el) {
    case 'form':
      return (
        <div className={containerClass}>
          <BackgroundImage />
          <form
            className={klass}
            onSubmit={(ev) => {
              onSubmit?.(ev as unknown as Event);
            }}>
            {children}
          </form>
        </div>
      );

    case 'div':
    default:
      return (
        <div className={containerClass}>
          <BackgroundImage />
          <div className={klass}>{children}</div>
        </div>
      );
  }
};

function BackgroundImage() {
  return (
    <div
      className="h-full w-full fixed  flex flex-1  bg-clip-border bg-local bg-no-repeat bg-cover max-desktop-md:bg-fixed"
      style={{
        backgroundImage: 'url(/images/benfica/benfica_background.png)',
      }}
    />
  );
}

import { FC } from 'react';
import {
  Link,
  NosIconNavigationArrow,
  useI18n,
} from '@nosinovacao/nosid-mfe-common';
import { ReturnButtonSkin } from '../backButton.props';

export const BackButtonDefault: FC<ReturnButtonSkin> = ({
  display,
  goBack,
}) => {
  const { t } = useI18n();
  if (!display) return <></>;
  return (
    <div
      onClick={() => {
        goBack();
      }}
      id="BackButton"
      className="flex cursor-pointer max-desktop-sm:mb-2 justify-left items-center gap-2 desktop-sm:absolute desktop-sm:left-4 desktop-sm:top-6">
      <NosIconNavigationArrow orientation="left" width={20} height={20} />
      <Link className="no-underline !h-auto !block !border-0">
        {t('Common.Translation.Back')}
      </Link>
    </div>
  );
};

import { htmlClass } from '@nosinovacao/nosid-mfe-common';
import { FC } from 'react';
import { FooterSkinProps } from '../footer.props';
import { LogoutButton } from '@/components/logoutButton';

export const FooterDefault: FC<FooterSkinProps> = ({
  className,
  children,
  overflow,
  showLogoutButton,
  isDarkMode,
  blurClass,
  footerClassName,
}) => {
  const footerClass = htmlClass(
    'mt-auto w-full pb-10',
    'tablet-sm:sticky',
    'desktop-sm:mt-auto desktop-sm:pb-6',
    overflow
      ? `bottom-0 pt-6 ${
          blurClass ??
          (isDarkMode ? 'bg-grey-900 desktop-sm:bg-grey-800' : 'bg-white')
        }  sticky  desktop-sm:-bottom-1 desktop-sm:absolute`
      : 'tablet-sm:mt-14  desktop-sm:mt-auto  flex flex-row gap-6  ',
    footerClassName ?? '',
  );

  return (
    <div className={footerClass}>
      <div
        className={htmlClass(
          `flex ${
            !overflow ? 'desktop-sm:mt-10' : ''
          } flex-col tablet-sm:flex-row gap-6 desktop-sm:gap-4 px-5 w-full tablet-sm:px-0   tablet-sm:mx-auto tablet-sm:max-w-[532px] desktop-sm:px-[108px]  desktop-sm:max-w-[636px]`,
          className ?? '',
        )}>
        {showLogoutButton ? (
          <LogoutButton showLogoutButton={showLogoutButton}>
            {children}
          </LogoutButton>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

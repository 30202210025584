import { ErrorCodes, TemplatesService } from '@nosinovacao/nosid-mfe-common';
import { Action } from './action.enum';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class NavigationMap {
  private static readonly routes: Record<Action, string> = {
    //	AUTHENTICATION
    [Action.Unauthenticated]: '/authentication/username',
    [Action.UnauthenticatedV2]: '/authentication/username',
    [Action.UnauthenticatedSinglePageLogin]: '/authentication/login',
    [Action.UnauthenticatedSinglePageLoginV2]: '/authentication/login',
    [Action.PasswordInput]: '/authentication/password',
    [Action.PasswordInputLoginHint]: '/authentication/password',
    [Action.UserBlocked]: '/authentication/user-blocked',

    // MANDATORY-EMAIL
    [Action.MandatoryEmailCodeValidation]: '/mandatory-email/landing-page',
    [Action.MandatoryChangeEmail]: '/mandatory-email/change-email',
    [Action.MandatoryEmailValidated]: '/mandatory-email/complete',

    //	SIGN UP
    [Action.SignUp]: '/signup/landing-page',
    [Action.SignUpSendSms]: '/signup/send-sms',
    [Action.SignUpChangePhone]: '/signup/change-phone',
    [Action.SignUpPhoneCodeValidation]: '/signup/validate-code',
    [Action.SignUpComplete]: '/signup/complete',
    [Action.SignUpEmailValidated]: '/signup/email-verified',
    [Action.SignUpDigitalBirth]: '/signup/digital-birth/landing-page',
    [Action.SignUpByInvitation]: '/signup/digital-birth/landing-page',
    [Action.SignUpDigitalBirthComplete]: '/signup/digital-birth/complete',
    [Action.SignUpByInvitationComplete]: '/signup/digital-birth/complete',
    [Action.SignUpSocialNetwork]: '/signup/social/landing-page',
    [Action.SignUpSocialNetworkComplete]: '/signup/social/complete',
    [Action.SignUpPasswordInput]: '/signup/new-password',

    //	ACCOUNT ASSOCIATION
    [Action.AssociationsIdentifyPrimary]: '/account-association/landing-page',
    [Action.AssociationsPhoneIdentification]: '/account-association/phone',
    [Action.AssociationsPhoneIdentificationCodeValidation]:
      '/account-association/phone-code',
    [Action.AssociationsEmailIdentification]: '/account-association/email',
    [Action.AssociationsEmailIdentificationCodeValidation]:
      '/account-association/email-code',
    [Action.AssociationsNifIdentification]: '/account-association/nif',
    [Action.AssociationsPassportIdentification]:
      '/account-association/passport',
    [Action.AssociationsNifIdentificationContactSelection]:
      '/account-association/contact-selection',
    [Action.AssociationsPassportIdentificationContactSelection]:
      '/account-association/contact-selection',
    [Action.AssociationsNifIdentificationSecondValidationPhoneCodeValidation]:
      '/account-association/second-step-phone-code',
    [Action.AssociationsPassportIdentificationSecondValidationPhoneCodeValidation]:
      '/account-association/second-step-phone-code',
    [Action.AssociationsNifIdentificationSecondValidationEmailCodeValidation]:
      '/account-association/second-step-email-code',
    [Action.AssociationsPassportIdentificationSecondValidationEmailCodeValidation]:
      '/account-association/second-step-email-code',
    [Action.AssociationsShowDetailsSecondary]: '/account-association/confirm',
    [Action.AssociationsShowDetailsPrimary]: '/account-association/confirm',
    [Action.AssociationsDetailsMandatoryInfo]:
      '/account-association/mandatory-info',

    //	ACCOUNT SELECTION
    [Action.AccountSelection]: '/account-selection/select',

    //	TERMS CONDITIONS AND CONSENTS
    [Action.TermsAndConditions]: '/terms-consents/terms',
    [Action.Consents]: '/terms-consents/consents',
    [Action.PrivacyPolicies]: '/terms-consents/privacy-policies',

    //	ACCOUNT PROTECTION
    [Action.Protection]: '/account-protection/landing-page',
    [Action.ProtectionPhoneCodeValidation]: '/account-protection/phone-code',
    [Action.ProtectionEmailCodeValidation]: '/account-protection/email-code',
    [Action.ProtectionPhoneSendCode]: '/account-protection/send-sms',
    [Action.ProtectionComplete]: '/account-protection/complete',
    [Action.RollbackUserContacts]: '/account-protection/rollback-complete',

    //	PASSWORD RECOVERY
    [Action.PasswordRecovery]: '/password-recovery/landing-page',
    [Action.PasswordRecoveryPending]: '/password-recovery/pending-status',
    [Action.PasswordRecoveryMandatory]: '/password-recovery/mandatory',
    [Action.PasswordRecoveryEmailCodeValidation]:
      '/password-recovery/email-sent',
    [Action.PasswordRecoveryPhoneCodeValidation]: '/password-recovery/sms-sent',
    [Action.PasswordRecoveryContactSelection]:
      '/password-recovery/contact-selection',
    [Action.PasswordRecoveryNewPasswordDefinition]:
      '/password-recovery/new-password',
    [Action.PasswordRecoveryComplete]: '/password-recovery/complete',

    //	SOCIAL NETWORKS
    [Action.SocialNetworkAssociation]: '/social/associate-nosid',
    [Action.SocialNetworkAssociationSingleStep]: '/social/authentication/login',
    [Action.SocialNetworkAssociationComplete]: '/social/complete',

    //	PROFILES
    [Action.KidsProfileSelection]: '/profiles',
    [Action.KidsProfileCreation]: '/profiles/new',

    // TWO FACTOR AUTHENTICATION
    [Action.TwoFactorAuthCodeValidation]:
      '/two-factor-authentication/code-input',
    [Action.TwoFactorAuthCodeValidationEmail]:
      '/two-factor-authentication/code-input-email',

    [Action.TwoFactorAuthFallback]: '/two-factor-authentication/fallback',

    // TWO FACTOR PROMOTION
    [Action.TwoFactorAuthCodePromotion]: '/two-factor-authentication/promote',
    [Action.TwoFactorAuthCodeValidationPromotion]:
      '/two-factor-authentication/code-input',
    [Action.TwoFactorAuthCodeValidationEmailPromotion]:
      '/two-factor-authentication/code-input-email',

    // DEVICE CODE
    [Action.DeviceCodesUserSelection]: '/activate/select-user',
    [Action.DeviceCodesComplete]: '/activate/complete',

    //	GENERIC
    [Action.GenericCountryCodeConfirmation]:
      '/generic/country-code-confirmation',
    [Action.InvalidRequest]: '/generic/error',
    [Action.ApplicationNotAuthorizedByAdmin]: '/generic/unauthorized-user',
    [Action.ApplicationNotAuthorized]: '/generic/unauthorized-app',
    [Action.ApplicationPermissionRequested]: '/generic/permission-requested',

    // EXTRA ACTIONS WITHOUT PAGES
    [Action.SignUpDigitalBirthInit]: '',
    [Action.SignUpByInvitationInit]: '',
    [Action.SignUpEmailValidation]: '',
    [Action.UnauthorizedKid]: '',
    [Action.RedirectTo]: '',
    [Action.LoggedOut]: '',
    [Action.InvalidTokenError]: '',
    [Action.ForbidenTokenError]: '',
    [Action.UnknownError]: '',
  };

  static getRoutePath(
    action: Action,
    errorCode?: ErrorCodes,
    templates?: TemplatesService,
  ): string | undefined {
    const route = this.routes[action];
    return this.isSpecialRedirect(errorCode, templates) ??
      route?.trim().length === 0
      ? '/generic/error'
      : route;
  }

  static isSpecialRedirect(
    errorCode?: ErrorCodes,
    templates?: TemplatesService,
  ) {
    return errorCode === ErrorCodes.UserNotFound && templates?.isWooTemplate
      ? '/generic/error'
      : null;
  }

  static isEntryRoute(url: string): boolean {
    return (
      url === '/' ||
      NavigationMap.isAuthorize(url) ||
      NavigationMap.isLogout(url) ||
      NavigationMap.emailVerify(url) ||
      NavigationMap.isDirectSignup(url) ||
      NavigationMap.isDigitalBirth(url) ||
      NavigationMap.isDeviceCode(url) ||
      NavigationMap.isPasswordRecoveryFromEmail(url) ||
      NavigationMap.isContactRollback(url)
    );
  }

  private static isLogout(url: string): boolean {
    return url.includes('logout');
  }

  private static isAuthorize(url: string): boolean {
    return url.includes('authorize') && !url.includes('unauthorized');
  }

  private static emailVerify(url: string): boolean {
    return url.includes('email-verify');
  }

  private static isDirectSignup(url: string): boolean {
    const [path] = url.split('?');
    return path === '/signup';
  }

  private static isDigitalBirth(url: string): boolean {
    const [path] = url.split('?');
    return path === '/signup/digital-birth/init';
  }

  private static isDeviceCode(url: string): boolean {
    const [path] = url.split('?');
    return path === '/activate';
  }

  private static isPasswordRecoveryFromEmail(url: string): boolean {
    const [path] = url.split('?');
    return path === '/password-recovery/from-email';
  }

  private static isContactRollback(url: string): boolean {
    const [path] = url.split('?');
    return path === '/account-protection/rollback';
  }
}

import { FC } from 'react';
import { FooterDefault } from './default/footer.default';
import { FooterWtf } from './wtf/footer.wtf';
import { useAppContext } from '@/context';
import { FooterProps, FooterSkinProps } from './footer.props';
import { FooterSporting } from './sporting/footer.sporting';
import { FooterWoo } from './woo/footer.woo';
import { FooterBenfica } from './benfica/footer.benfica';

export const Footer: FC<FooterProps> = ({
  className,
  children,
  showLogoutButton,
  ...props
}) => {
  const { templates, canUseDarkTheme } = useAppContext();
  const skinProps: FooterSkinProps = {
    blurClass: props.blur
      ? "bg-gradient-to-t 'from-black/95  to-black/100' backdrop-blur-[2px]"
      : undefined,
    variant: templates.clientTemplate.App,
    children,
    className,
    isDarkMode: canUseDarkTheme,
    showLogoutButton,
    ...props,
  };

  switch (true) {
    case templates.isWtfTemplate:
      return <FooterWtf {...skinProps}>{children}</FooterWtf>;
    case templates.isSportingTemplate:
      return <FooterSporting {...skinProps}>{children}</FooterSporting>;
    case templates.isWooTemplate:
      return <FooterWoo {...skinProps}>{children}</FooterWoo>;
    case templates.isBenficaTemplate:
      return <FooterBenfica {...skinProps}>{children}</FooterBenfica>;
    default:
      return <FooterDefault {...skinProps}>{children}</FooterDefault>;
  }
};

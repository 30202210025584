import { FC } from 'react';
import {
  Button,
  IconLoader,
  NosIconFeedbackWarning,
  NosIconLock,
  NosTypography,
  useI18n,
  useInMediaQuery,
} from '@nosinovacao/nosid-mfe-common';
import { ErrorSkinProps } from '../error.props';
import { Container } from '@/components/container';
import { Footer } from '@/components/footer';
import { SubContainer } from '@/components/subcontainer';
import { Header } from '@/components/header';

export const ErrorDefault: FC<ErrorSkinProps> = ({
  action,
  button,
  level,
  title,
  tooltip,
  onClick,
  loadingIndex,
  setLoadingIndex,
}) => {
  const { t } = useI18n();
  const isDesktop = useInMediaQuery('desktop-sm');
  const getIcon = (width: number, height: number) => {
    switch (level) {
      case 'warning':
        return (
          <NosIconFeedbackWarning
            className="fill-yellow"
            width={width}
            height={height}
          />
        );
      case 'error':
        return (
          <NosIconLock className="fill-red" width={width} height={height} />
        );
      default:
        <></>;
    }
  };
  return (
    <Container>
      <SubContainer>
        <Header displayLogo>
          <div className="flex flex-col justify-start items-start gap-2 desktop-sm:items-center mt-10">
            {getIcon(48, 48)}
            <div className="flex flex-col justify-start items-start gap-4 desktop-sm:items-center">
              <NosTypography variant="headline" className="Error">
                {t(title)}
              </NosTypography>
              <NosTypography
                variant={isDesktop ? 'body-Regular' : 'body-Medium'}>
                {t(tooltip)}
              </NosTypography>
            </div>
          </div>
        </Header>
      </SubContainer>
      {button && (
        <Footer
          showLogoutButton={{
            lastItem: true,
            execute: () => {
              setLoadingIndex(1);
            },
            isLoading: loadingIndex === 1,
            disabled: loadingIndex >= 0,
          }}
          className=" tablet-sm:mt-40 w-full desktop-sm:pb-0 desktop-sm:mt-auto">
          {action && (
            <Button
              format="primary"
              className="w-full"
              disabled={loadingIndex >= 0}
              onClick={onClick}>
              {loadingIndex === 2 ? (
                <IconLoader height={22} width={22} />
              ) : (
                t(button)
              )}
            </Button>
          )}
        </Footer>
      )}
    </Container>
  );
};

import { Button, IconLoader } from '@nosinovacao/nosid-mfe-common';
import { Footer } from '@/components/footer';
import { FooterProps } from './code-input.props';

export const FooterByType = ({
  form,
  loadingIndex,
  loadingResend,
  setLoadingIndex,
  type,
  children,
  className,
  goBack,
  translationKey,
  t,
}: FooterProps) => {
  switch (type) {
    case 'Phone':
      return (
        <Footer
          showLogoutButton={{
            execute: () => {
              setLoadingIndex(1);
            },
            lastItem: false,
            type: 'button',
            disabled: form.submitting || loadingResend || loadingIndex >= 0,
            isLoading: loadingIndex === 1,
          }}
          className={className}>
          {children}
        </Footer>
      );
    default:
      return (
        <Footer className={className}>
          <Button
            id="CodeInputButton"
            className="w-full"
            format="secondary"
            onClick={goBack}
            type="submit"
            disabled={form.submitting || loadingResend || loadingIndex >= 0}>
            {loadingIndex === 1 ? (
              <IconLoader height={22} width={22} />
            ) : (
              t(`${translationKey}.GoBackButton`)
            )}
          </Button>
          {children}
        </Footer>
      );
  }
};

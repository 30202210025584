import { useBaseNosidStoreState, useI18n } from '@nosinovacao/nosid-mfe-common';
import { useAppContext } from '@/context';
import { ResponseAction } from '@/models';
import { useNavigation } from '@/navigation';
import { useNosidSignInMutation } from './mutation.hook';
import { getEndpointByVersion } from './base-hooks';

export function useResendCode(onResponse?: (data: ResponseAction) => void) {
  const { config, navigationStateService, templates } = useAppContext();
  const { navigateToError } = useNavigation();
  const { show } = useBaseNosidStoreState().toast;
  const { t } = useI18n();
  const { mutate, isLoading } = useNosidSignInMutation(
    async (axios) => {
      const headers: Record<string, string | undefined> = {
        Authorization: `Bearer ${
          navigationStateService.getCurrentState()?.Token
        }`,
      };

      return await axios
        .post<ResponseAction>(
          getEndpointByVersion(
            config.APIConfiguration.AA.Endpoint.CodeResend,
            config,
            templates,
          ),
          {},
          {
            headers,
          },
        )
        .then((response) => response.data);
    },
    {
      loader: false,
      onResponse: (data) => {
        onResponse?.(data);
        if (data.Error) {
          navigateToError(data);
        } else {
          show({
            title: t('Common.SnackBar.ResendCode.Success'),
            type: 'success',
          });
        }
      },
    },
  );

  return { mutate, isLoading };
}

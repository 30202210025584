import {
  Button,
  Code,
  Captcha,
  Link,
  IconLoader,
  NosTypography,
  useI18n,
} from '@nosinovacao/nosid-mfe-common';
import { FC } from 'react';

import { Container } from '@/components/container';
import { useAppContext } from '@/context';
import { TwoFactorAuthenticationCodeInputProps } from '../code-input.props';
import { Header } from '@/components/header';
import { SubContainer } from '@/components/subcontainer';
import { FooterByType } from '../footer-by-type';

export const TwoFactorAuthenticationCodeInputWtf: FC<
  TwoFactorAuthenticationCodeInputProps
> = ({
  challenge,
  form,
  formErrors,
  goBack,
  onSubmitForm,
  resend,
  setRecaptchaToken,
  translationKey,
  contact,
  loadingResend,
  loadingIndex,
  setLoadingIndex,
  type,
  showReturnButton,
}) => {
  const { t } = useI18n();
  const { widgetsConfig, config } = useAppContext();
  return (
    <Container
      className="flex flex-col justify-start items-start  "
      onSubmit={form.handleSubmit(onSubmitForm)}
      el="form">
      <Header displayReturnButton={{ display: showReturnButton, goBack }}>
        <div className="mt-2 flex flex-col justify-start items-start ">
          <NosTypography
            id="Title"
            variant="headlineL"
            className=" w-full text-center">
            {t(`${translationKey}.Title`)}
          </NosTypography>

          <NosTypography
            id="Subtitle"
            variant="longText-Medium"
            className=" mt-4 w-full text-center">
            {t(`${translationKey}.Subtitle`, { contact })}
          </NosTypography>
        </div>
      </Header>
      <SubContainer>
        <Code
          id="2fa-code"
          autoComplete="one-time-code"
          length={widgetsConfig.ValidationCodeLength}
          setValue={(v) => {
            form.setValue('Code', v);
          }}
          value={form.value.Code}
          errors={formErrors.Code ? [t(formErrors.Code)] : []}
          className="mt-12 mx-auto "
        />

        {loadingResend ? (
          <div className="mt-12 mx-auto ">
            <IconLoader height={23} width={23} />
          </div>
        ) : (
          <Link
            action={resend}
            underline
            className="mt-12 mx-auto "
            disabled={form.submitting}>
            {t(`${translationKey}.Resendcode`)}
          </Link>
        )}

        {challenge && (
          <Captcha
            className="mx-auto mt-6"
            onChange={setRecaptchaToken}
            siteKey={config.CloudflareRecaptchaChallengeSiteKey}
          />
        )}
      </SubContainer>

      <FooterByType
        type={type}
        setLoadingIndex={setLoadingIndex}
        loadingIndex={loadingIndex}
        form={form}
        loadingResend={loadingResend}
        translationKey={translationKey}
        goBack={goBack}
        t={t}
        className=" mt-auto tablet-sm:mt-14 w-full">
        <Button
          id="CodeInputButton"
          className="w-full"
          type="submit"
          disabled={
            form.pristine ||
            form.submitting ||
            loadingResend ||
            loadingIndex >= 0
          }>
          {loadingIndex === 2 ? (
            <IconLoader height={22} width={22} />
          ) : (
            t(`${translationKey}.Button`)
          )}
        </Button>
      </FooterByType>
    </Container>
  );
};

/* eslint-disable no-plusplus */
import { IStorage, ModuleName, Screen } from '@nosinovacao/nosid-mfe-common';
import { NAVIGATION_HISTORY_STORAGE_KEY } from '@/constants';
import { Action, ResponseAction } from '@/models';

type History = Map<string, ResponseAction>;

type Url = { url: string } | null;

export class NavigationStateService {
  private history: History = new Map();

  constructor(private readonly sessionStorage: IStorage) {
    this.loadSessionStorage();
  }

  reset(): void {
    this.resetSessionStorage();
    this.loadSessionStorage();
  }

  hasThePage(moduleName: ModuleName, screen: Screen): boolean {
    return [...this.history.keys()].some((obj) => {
      return obj.includes(
        `/${moduleName.toLowerCase()}/${screen.toLowerCase()}`,
      );
    });
  }

  saveNavigation(eventUrl: string, data: ResponseAction<any>): void {
    if (!data.Token) {
      data = {
        ...data,
        Token: this.getCurrentState()?.Token ?? '',
      };
    }
    if (!data.LoginHint) {
      data = {
        ...data,
        LoginHint: data.LoginHint,
      };
    }
    this.history.set(eventUrl, data);
    this.saveSessionStorage();
  }

  get firstState(): boolean {
    return [...this.history.keys()].length === 1;
  }

  getCurrentUrl(): string {
    return window.location.href.replace(window.location.origin, '');
  }

  getPreviousUrlData(): ResponseAction<any> | null {
    const currentState = this.getCurrentState();

    if (!currentState?.PreviousAction) {
      return null;
    }

    const func = (action: ResponseAction<unknown>) =>
      action.Action === currentState?.PreviousAction;

    return this.returnSpecificUrl(func);
  }

  getSpecificUrlData(actionToLookFor: Action): ResponseAction<any> | null {
    const func = (action: ResponseAction<unknown>) =>
      action.Action === actionToLookFor;

    return this.returnSpecificUrl(func);
  }

  getNextUrlData(avoidSameModule: boolean): ResponseAction<any> | null {
    const currentState = this.getCurrentState();

    if (!currentState?.Action) {
      return null;
    }

    const isNotTheSamePage = (action: ResponseAction<unknown>) =>
      avoidSameModule && !action?.Action?.includes(currentState?.Action);

    const func = (action: ResponseAction<unknown>) =>
      action.PreviousAction === currentState?.Action &&
      isNotTheSamePage(action);

    return this.returnSpecificUrl(func);
  }

  private returnHistoryKeys(
    func: (action: ResponseAction<unknown>) => boolean,
  ): Url[] {
    return [...this.history.entries()]
      ?.filter((item) =>
        item.find((actionParam) =>
          func(actionParam as ResponseAction<unknown>),
        ),
      )
      ?.filter((item) => item.length > 0 && item[0].split('#').length > 0)
      ?.map((item) => {
        return {
          shortUrl: item[0]?.split('#')[0],
          url: item[0],
        };
      });
  }

  private returnSpecificUrl(
    func: (action: ResponseAction<unknown>) => boolean,
  ): ResponseAction<any> | null {
    const historyKeys = this.returnHistoryKeys(func)?.pop();

    return (historyKeys && this.history.get(historyKeys.url)) ?? null;
  }

  getCurrentState(): ResponseAction<any> | null {
    return this.history.has(this.getCurrentUrl())
      ? this.history.get(this.getCurrentUrl()) ?? null
      : null;
  }

  updateCurrentStateToken(token: string): void {
    const currentState = this.getCurrentState();
    if (currentState) {
      currentState.Token = token;
      this.saveSessionStorage();
    }
  }

  updateCurrentState(data: ResponseAction) {
    this.history.set(this.getCurrentUrl(), {
      ...(this.getCurrentState() ?? {}),
      ...data,
    });
    this.saveSessionStorage();
  }

  private resetSessionStorage(): void {
    this.sessionStorage.remove(NAVIGATION_HISTORY_STORAGE_KEY);
  }

  private loadSessionStorage(): void {
    if (this.sessionStorage.checkItem(NAVIGATION_HISTORY_STORAGE_KEY)) {
      this.history = new Map(
        JSON.parse(
          this.sessionStorage.getString(NAVIGATION_HISTORY_STORAGE_KEY) ?? '',
        ),
      );
    } else {
      this.history = new Map();
    }
  }

  private saveSessionStorage(): void {
    this.sessionStorage.setString(
      NAVIGATION_HISTORY_STORAGE_KEY,
      JSON.stringify([...this.history]),
    );
  }
}

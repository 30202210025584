import { htmlClass } from '@nosinovacao/nosid-mfe-common';
import { FC } from 'react';
import { ContainerSkinProps } from '../container.props';

export const ContainerSporting: FC<ContainerSkinProps> = ({
  children,
  className,
  isDarkMode,
  el,
  onSubmit,
}) => {
  const klass = htmlClass(
    className ?? '',
    'max-desktop-sm:overflow-x-hidden desktop-sm:w-svw relative pt-6  flex flex-1  flex-col justify-start items-start  z-10 max-desktop-sm:h-svh',
    'tablet-sm:pt-[120px] tablet-sm:mx-auto ',
    'tablet-st:px-0',
    'desktop-sm:rounded-lg desktop-sm:overflow-y-auto   desktop-sm:mx-0 desktop-sm:max-w-[566px]   desktop-sm:pt-0 desktop-sm:overflow-hidden',
  );

  const containerClass = htmlClass(
    ' desktop-sm:flex  desktop-sm:h-[100dvh] desktop-sm:w-svw bg-white',
  );

  switch (el) {
    case 'form':
      return (
        <>
          <div className={containerClass}>
            <BackgroundImage />
            <form
              className={klass}
              onSubmit={(ev) => {
                onSubmit?.(ev as unknown as Event);
              }}>
              {children}
            </form>
          </div>
        </>
      );

    case 'div':
    default:
      return (
        <>
          <div className={containerClass}>
            <BackgroundImage />
            <div className={klass}>{children}</div>
          </div>
        </>
      );
  }
};

function BackgroundImage() {
  return (
    <div
      className="h-full  flex flex-1  bg-clip-border bg-local bg-no-repeat bg-cover max-desktop-md:bg-fixed"
      style={{
        backgroundImage: 'url(/images/sporting/background_lion.png)',
        backgroundPosition: 'center left',
      }}
    />
  );
}

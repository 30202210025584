import {
  ActionType,
  useBaseNosidStoreState,
} from '@nosinovacao/nosid-mfe-common';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '@/context';
import { useNavigation } from '@/navigation';

export function useGoBack() {
  const navigate = useNavigate();
  const { navigateTo } = useNavigation();
  const { logger, navigationStateService } = useAppContext();
  const previousPageData = navigationStateService.getPreviousUrlData();
  const { activeModule, activeScreen } = useBaseNosidStoreState().navigation;

  return (clearHistoryBack = false) => {
    logger.sendScreenNavigationEvent(
      {
        ActionType: ActionType.Back,
      },
      activeModule,
      activeScreen,
    );

    // safe guard because depending on how useGoBack is used the variable
    // clearHistoryBack can be a string or an object because of browser
    typeof clearHistoryBack === 'boolean' &&
    clearHistoryBack &&
    previousPageData
      ? navigateTo(previousPageData, '', true)
      : navigate(-1);
  };
}

import {
  ActionType,
  ExceptionType,
  NOSIDError,
  useBaseNosidStoreState,
} from '@nosinovacao/nosid-mfe-common';
import { useAppContext } from '@/context';
import { ResponseAction } from '@/models';

function createExceptionMessageServerError(errors: NOSIDError[]) {
  return errors.map((error: NOSIDError) => {
    return error.Code;
  });
}

export function useCreateLogEvent() {
  const { logger } = useAppContext();
  const { activeModule, activeScreen } = useBaseNosidStoreState().navigation;

  return (
    res: ResponseAction<any>,
    actionType: ActionType,
    option: string | null = null,
  ) => {
    if (res.Error) {
      logger.sendExceptionEvent(
        {
          ActionType: actionType,
          Option: option,
          ExceptionType: ExceptionType.Server,
          ExceptionMessage:
            res.Error.Code != null
              ? [res.Error.Code]
              : createExceptionMessageServerError(res.Error.Params ?? []),
        },
        activeModule,
        activeScreen,
      );
    } else {
      switch (actionType) {
        case ActionType.ChangeContact:
        case ActionType.Signup:
        case ActionType.PasswordRecovery:
        case ActionType.Cancel:
        case ActionType.SocialNetworks:
          logger.sendScreenNavigationEvent(
            {
              ActionType: actionType,
              Option: option,
            },
            activeModule,
            activeScreen,
          );
          break;
        default:
          logger.sendScreenActionEvent(
            {
              ActionType: actionType,
              Option: option,
            },
            activeModule,
            activeScreen,
          );
      }
    }
  };
}

import {
  CAPTCHA_CHALLENGE_HTTP_HEADER,
  CAPTCHA_HTTP_HEADER,
} from '@nosinovacao/nosid-mfe-common';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useAppContext } from '@/context';
import { ResponseAction, ValidateCode } from '@/models';
import { useNavigation } from '@/navigation';
import { useNosidSignInMutation } from './mutation.hook';
import { getEndpointByVersion } from './base-hooks';

export function useInitSend2FaEmailCode(
  onResponse: (data: ResponseAction) => void,
) {
  const { config, navigationStateService, templates } = useAppContext();
  const { navigateTo, navigateToError } = useNavigation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { mutate, isLoading, error } = useNosidSignInMutation(
    async (
      axios,
      input: { promote: boolean; tokenChallenge?: string | null },
    ) => {
      const headers: Record<string, string | undefined> = {
        Authorization: `Bearer ${
          navigationStateService.getCurrentState()?.Token
        }`,
      };

      if (input.tokenChallenge) {
        headers[CAPTCHA_HTTP_HEADER] = input.tokenChallenge;
        headers[CAPTCHA_CHALLENGE_HTTP_HEADER] = 'true';
      } else {
        headers[CAPTCHA_HTTP_HEADER] = await executeRecaptcha?.(
          'TwoFactorAuthByEmail',
        );
      }
      return await axios
        .post<ResponseAction>(
          getEndpointByVersion(
            input.promote
              ? config.APIConfiguration.AA.Endpoint.TwoFactorAuthPromoteByEmail
              : config.APIConfiguration.AA.Endpoint.TwoFactorAuthByEmail,
            config,
            templates,
          ),
          {},
          {
            headers,
          },
        )
        .then((response) => response.data);
    },
    {
      loader: false,
      onResponse: (data) => {
        onResponse(data);
        if (data.Error) {
          navigateToError(data);
        } else {
          navigateTo(data, '', true);
        }
      },
    },
  );

  return { mutate, isLoading, error };
}

export function useValidate2FaCode(onResponse: (data: ResponseAction) => void) {
  const { config, navigationStateService, templates } = useAppContext();
  const { navigateTo, navigateToError } = useNavigation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { mutateAsync } = useNosidSignInMutation(
    async (
      axios,
      input: { code: string; promote: boolean; tokenChallenge?: string | null },
    ) => {
      const headers: Record<string, string | undefined> = {
        Authorization: `Bearer ${
          navigationStateService.getCurrentState()?.Token
        }`,
      };

      const body: ValidateCode = {
        Value: input.code,
      };
      if (input.tokenChallenge) {
        headers[CAPTCHA_HTTP_HEADER] = input.tokenChallenge;
        headers[CAPTCHA_CHALLENGE_HTTP_HEADER] = 'true';
      } else {
        headers[CAPTCHA_HTTP_HEADER] = await executeRecaptcha?.(
          'TwoFactorAuthCodeValidation',
        );
      }
      return await axios
        .post<ResponseAction>(
          getEndpointByVersion(
            input.promote
              ? config.APIConfiguration.AA.Endpoint
                  .TwoFactorAuthPromoteCodeValidation
              : config.APIConfiguration.AA.Endpoint.TwoFactorAuthCodeValidation,
            config,
            templates,
          ),
          body,
          {
            headers,
          },
        )
        .then((response) => response.data);
    },
    {
      loader: false,
      onResponse: (data) => {
        onResponse(data);
        if (data.Error) {
          navigateToError(data);
        } else {
          navigateTo(data);
        }
      },
    },
  );

  return mutateAsync;
}

export function useFallBack(onResponse: (data: ResponseAction) => void) {
  const { config, navigationStateService, templates } = useAppContext();
  const { navigateTo, navigateToError } = useNavigation();
  const { mutate, isLoading, error } = useNosidSignInMutation(
    async (axios) => {
      const headers: Record<string, string | undefined> = {
        Authorization: `Bearer ${
          navigationStateService.getCurrentState()?.Token
        }`,
      };

      return await axios
        .get<ResponseAction>(
          getEndpointByVersion(
            config.APIConfiguration.AA.Endpoint.TwoFactorAuthFallback,
            config,
            templates,
          ),
          {
            headers,
          },
        )
        .then((response) => response.data);
    },
    {
      loader: false,
      onResponse: (data) => {
        onResponse(data);
        if (data.Error) {
          navigateToError(data);
        } else {
          navigateTo(data);
        }
      },
    },
  );

  return { mutate, isLoading, error };
}

export function useInit2FaPromotion(
  onResponse: (data: ResponseAction) => void,
) {
  const { config, navigationStateService, templates } = useAppContext();
  const { navigateTo, navigateToError } = useNavigation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { mutate, isLoading } = useNosidSignInMutation(
    async (axios, tokenChallenge: string | undefined) => {
      const headers: Record<string, string | undefined> = {
        Authorization: `Bearer ${
          navigationStateService.getCurrentState()?.Token
        }`,
      };

      if (tokenChallenge) {
        headers[CAPTCHA_HTTP_HEADER] = tokenChallenge;
        headers[CAPTCHA_CHALLENGE_HTTP_HEADER] = 'true';
      } else {
        headers[CAPTCHA_HTTP_HEADER] = await executeRecaptcha?.(
          'TwoFactorAuthCodeInput',
        );
      }
      return await axios
        .post<ResponseAction>(
          getEndpointByVersion(
            config.APIConfiguration.AA.Endpoint.TwoFactorAuthPromoteCodeInput,
            config,
            templates,
          ),
          {},
          {
            headers,
          },
        )
        .then((response) => response.data);
    },
    {
      loader: false,
      onResponse: (data) => {
        onResponse(data);
        if (data.Error) {
          navigateToError(data);
        } else {
          navigateTo(data);
        }
      },
    },
  );

  return { mutate, isLoading };
}

import { htmlClass } from '@nosinovacao/nosid-mfe-common';
import { FC } from 'react';
import { SubContainerSkinProps } from '../subcontainer.props';

export const SubContainerDefault: FC<SubContainerSkinProps> = ({
  el,
  children,
  className,
  mainClassName,
  ...props
}) => {
  const kclass = htmlClass(
    `flex flex-col px-5  w-full`,
    `tablet-sm:max-w-[532px]  tablet-sm:mx-auto tablet-sm:px-0 `,
    `desktop-sm:pt-6 desktop-sm:px-[108px] desktop-sm:max-w-[636px] desktop-sm:relative desktop-sm:overflow-visible`,
    mainClassName ?? '',
  );

  return (
    <div className={kclass}>
      <div className={htmlClass(className ?? '', 'flex flex-col')}>
        {children}
      </div>
    </div>
  );
};

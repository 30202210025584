import { NOSIDError } from '@nosinovacao/nosid-mfe-common';
import React from 'react';
import { Action, ResponseAction } from '@/models';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface NavigationContextType<T = any> {
  data: ResponseAction<T> | null;
  previousPageData: ResponseAction<T> | null;
  nextPageFunc: (avoidSameModule?: boolean) => ResponseAction<any> | null;
  error: NOSIDError | undefined;
  navigateTo: (
    res: ResponseAction<unknown>,
    queryParams?: string,
    replaceCurrentPage?: boolean,
  ) => void;
  navigateToError: (res: ResponseAction<unknown>) => void;
  updateCurrentDataState: (Data?: any, clearErrors?: boolean) => void;
  clearError: () => void;
  navigateToSpecificAction: (
    action: Action,
    replaceCurrentPage?: boolean,
  ) => void;
}

export const NavigationContext = React.createContext<NavigationContextType>(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as NavigationContextType,
);

export function useNavigation<T>(): NavigationContextType<T> {
  return React.useContext(NavigationContext);
}

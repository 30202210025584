import { FC } from 'react';
import {
  Link,
  NosIconNavigationArrow,
  useI18n,
  useInMediaQuery,
} from '@nosinovacao/nosid-mfe-common';
import { ReturnButtonSkin } from '../backButton.props';

export const BackButtonSporting: FC<ReturnButtonSkin> = ({
  display,
  goBack,
}) => {
  const { t } = useI18n();
  const isDesktop = useInMediaQuery('desktop-sm');
  if (!display) return <></>;
  return (
    <div
      onClick={() => {
        goBack();
      }}
      id="BackButton"
      className="flex cursor-pointer max-desktop-sm:mb-2 justify-left items-center gap-2 desktop-sm:absolute desktop-sm:left-12 desktop-sm:top-[2.6rem]">
      <NosIconNavigationArrow orientation="left" width={20} height={20} />
      <Link className="!h-auto !block" underline={isDesktop}>
        {t('Common.Translation.Back')}
      </Link>
    </div>
  );
};

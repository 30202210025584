import { useEffect, useMemo, useState } from 'react';
import { ErrorCodes } from '@nosinovacao/nosid-mfe-common';
import { useNavigation } from '@/navigation';

export function useSignInCaptchaV2() {
  const navigationState = useNavigation();
  const [token, setToken] = useState<string | null | undefined>(null);

  const data = useMemo(() => {
    return navigationState.data;
  }, [navigationState]);

  useEffect(() => {
    setToken('');
  }, [data]);

  const h = useMemo(() => {
    return {
      challenge: data?.Error?.Code === ErrorCodes.Challenge,
      token,
      setToken,
    };
  }, [data, token]);

  return h;
}

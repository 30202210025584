import { ErrorCodes } from '@nosinovacao/nosid-mfe-common';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppContext } from '@/context';
import { Action } from '@/models';
import { useNavigation } from '@/navigation';

export function useValidActionGuard(...actions: Action[]) {
  const { navigationStateService } = useAppContext();
  const { navigateToError } = useNavigation();
  const { hash, pathname } = useLocation();
  useEffect(() => {
    const currentState = navigationStateService.getCurrentState();
    if (
      !currentState ||
      !actions.some((action) => action === currentState.Action)
    ) {
      navigateToError({
        Action: Action.ForbidenTokenError,
        Data: null,
        Error: {
          Code: ErrorCodes.ForbidenTokenError,
          ErrorCode: ErrorCodes.ForbidenTokenError,
        },
        Token: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash, pathname]);
}

import {
  Button,
  Code,
  Captcha,
  Link,
  IconLoader,
  NosTypography,
  useI18n,
  useInMediaQuery,
} from '@nosinovacao/nosid-mfe-common';
import { FC } from 'react';

import { Container } from '@/components/container';
import { useAppContext } from '@/context';
import { TwoFactorAuthenticationCodeInputProps } from '../code-input.props';
import { SubContainer } from '@/components/subcontainer';
import { Header } from '@/components/header';
import { FooterByType } from '../footer-by-type';

export const TwoFactorAuthenticationCodeInputDefault: FC<
  TwoFactorAuthenticationCodeInputProps
> = ({
  challenge,
  form,
  formErrors,
  goBack,
  onSubmitForm,
  resend,
  setRecaptchaToken,
  translationKey,
  contact,
  loadingResend,
  loadingIndex,
  setLoadingIndex,
  type,
  showReturnButton,
}) => {
  const { t } = useI18n();
  const { widgetsConfig, config } = useAppContext();
  const isDesktop = useInMediaQuery('desktop-sm');
  return (
    <Container onSubmit={form.handleSubmit(onSubmitForm)} el="form">
      <SubContainer>
        <Header
          displayLogo
          displayReturnButton={{
            display: showReturnButton,
            goBack,
          }}>
          <div className="flex flex-col justify-start items-start desktop-sm:items-center">
            <NosTypography
              id="Title"
              className="TwoFactorAuthenticationCodeInput"
              variant={isDesktop ? 'sectionTitle' : 'headline'}>
              {t(`${translationKey}.Title`)}
            </NosTypography>

            <NosTypography
              id="Subtitle"
              variant={isDesktop ? 'body-Regular' : 'longText-Regular'}
              className={` mt-4 desktop-sm:text-center desktop-sm:mt-2`}>
              {t(`${translationKey}.Subtitle`, { contact })}
            </NosTypography>
          </div>
        </Header>
        <div className="flex flex-col items-center justify-center mr-1">
          <Code
            id="2fa-code"
            autoComplete="one-time-code"
            length={widgetsConfig.ValidationCodeLength}
            setValue={(v) => {
              form.setValue('Code', v);
            }}
            value={form.value.Code}
            errors={formErrors.Code ? [t(formErrors.Code)] : []}
            className="mt-12 desktop-sm:mt-14"
          />
          <div className="mt-12  desktop-sm:mt-10">
            {loadingResend ? (
              <div>
                <IconLoader height={23} width={23} />
              </div>
            ) : (
              <Link action={resend} underline disabled={form.submitting}>
                {t(`${translationKey}.Resendcode`)}
              </Link>
            )}
          </div>
        </div>

        {challenge && (
          <Captcha
            className="mx-auto mt-5 !mb-2"
            onChange={setRecaptchaToken}
            siteKey={config.CloudflareRecaptchaChallengeSiteKey}
          />
        )}
      </SubContainer>
      <FooterByType
        type={type}
        setLoadingIndex={setLoadingIndex}
        loadingIndex={loadingIndex}
        form={form}
        loadingResend={loadingResend}
        translationKey={translationKey}
        goBack={goBack}
        t={t}>
        <Button
          id="CodeInputButton"
          className="w-full"
          type="submit"
          disabled={
            form.pristine ||
            form.submitting ||
            loadingResend ||
            loadingIndex >= 0
          }>
          {loadingIndex === 2 ? (
            <IconLoader height={22} width={22} />
          ) : (
            t(`${translationKey}.Button`)
          )}
        </Button>
      </FooterByType>
    </Container>
  );
};

import { htmlClass } from '@nosinovacao/nosid-mfe-common';
import { FC } from 'react';
import { FooterSkinProps } from '../footer.props';
import { LogoutButton } from '@/components/logoutButton';

export const FooterSporting: FC<FooterSkinProps> = ({
  className,
  children,
  overflow,
  showLogoutButton,
  blurClass,
  footerClassName,
}) => {
  const footerClass = htmlClass(
    'mt-8 w-full pb-10 mt-auto flex-1',
    'tablet-sm:sticky',
    'desktop-sm:mt-8 desktop-sm:pb-6',
    overflow
      ? `bottom-0 pt-6 ${blurClass ?? 'bg-white'} 
        sticky  desktop-sm:-bottom-1 desktop-sm:absolute`
      : 'tablet-sm:mt-14 flex flex-row gap-6  ',
    footerClassName ?? '',
  );

  return (
    <div className={footerClass}>
      <div
        className={htmlClass(
          `flex mt-auto flex-col tablet-sm:flex-row gap-6 px-5 w-full tablet-sm:px-0   tablet-sm:mx-auto tablet-sm:max-w-[532px] desktop-sm:px-14  desktop-sm:max-w-[636px] tablet-sm:!mt-0`,
          className ?? '',
        )}>
        {showLogoutButton ? (
          <LogoutButton showLogoutButton={showLogoutButton}>
            {children}
          </LogoutButton>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

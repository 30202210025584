import { lazy } from 'react';
import { ComponentContainer } from '@/components/componentContainer';
import { TwoFactorType } from '@/pages/TwoFactorAuthentication/CodeInput';

const CodeInput = lazy(
  async () => await import('@/pages/TwoFactorAuthentication/CodeInput'),
);

const Fallback = lazy(
  async () => await import('@/pages/TwoFactorAuthentication/Fallback'),
);

const Promote = lazy(
  async () => await import('@/pages/TwoFactorAuthentication/Promote'),
);

export default [
  {
    path: 'two-factor-authentication/code-input',
    element: (
      <ComponentContainer>
        <CodeInput type={TwoFactorType.Phone} />
      </ComponentContainer>
    ),
  },
  {
    path: 'two-factor-authentication/fallback',
    element: (
      <ComponentContainer>
        <Fallback />
      </ComponentContainer>
    ),
  },
  {
    path: 'two-factor-authentication/code-input-email',
    element: (
      <ComponentContainer>
        <CodeInput type={TwoFactorType.Email} />
      </ComponentContainer>
    ),
  },
  {
    path: 'two-factor-authentication/promote',
    element: (
      <ComponentContainer>
        <Promote />
      </ComponentContainer>
    ),
  },
];

import { FC } from 'react';
import { Button, htmlClass, useI18n } from '@nosinovacao/nosid-mfe-common';
import { ReturnButtonSkin } from '../backButton.props';

export const BackButtonWoo: FC<ReturnButtonSkin> = ({
  display,
  className,
  goBack,
}) => {
  const { t } = useI18n();
  if (!display) return <></>;
  return (
    <Button
      className={htmlClass(className ?? '', '!h-auto !block')}
      format="secondary"
      type="button"
      onClick={() => {
        goBack();
      }}>
      {t('Common.Translation.Back')}
    </Button>
  );
};
